import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about">
      <h2>About Us</h2>
      <p>With over 40 years of combined engineering leadership experience, Palindrome Coaches have experience strategizing how best to leverage technology to accomplish business and product goals.</p>
    </section>
  );
}

export default About;