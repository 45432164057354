import React from 'react';
import './Services.css';

function Services() {
  return (
    <section id="services">
      <h2>Our Services</h2>
      <div className="services">
        <div className="service">
          <h3>Leadership Coaching</h3>
          <p>Enhance your leadership skills and drive your team to success.</p>
        </div>
        <div className="service">
          <h3>Career Development</h3>
          <p>Develop a strategic plan to achieve your professional goals.</p>
        </div>
        <div className="service">
          <h3>Personal Growth</h3>
          <p>Unlock your potential with personalized coaching sessions.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;