import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section id="contact">
      <h2>Contact Us</h2>
      <p>Email: <a href="mailto:info@palindromecoaching.com">info@palindromecoaching.com</a></p>
    </section>
  );
}

export default Contact;
