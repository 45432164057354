import React from 'react';
import './Home.css';

function Home() {
  return (
    <section id="home">
      <h2>Welcome to Palindrome Coaching Services</h2>
      <p>Empower your leadership skills and achieve your career goals with our expert coaching.</p>
    </section>
  );
}

export default Home;