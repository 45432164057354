import React from 'react';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
       <div className="container">
      <Home />
        <About />
        <Services />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;